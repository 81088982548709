import React from "react"
import { graphql, Link } from "gatsby"
import LayoutDark from "../pages/shared/layout/layout-dark"
import Newsletter from "../pages/shared/components/newsletter"
import SignUpFormComponent from "../pages/shared/components/sign-up-form.component"
import { Helmet } from "react-helmet"

const routes = require("../types/routes")
export const query = graphql`
  query PostBySlug($slug: String!, $tag: String) {
    ghostPost(visibility: { eq: "public" }, slug: { eq: $slug }) {
      id
      html
      title
      feature_image
      visibility
      slug
      primary_tag {
        name
        slug
      }
      primary_author {
        name
        profile_image
        slug
      }
      reading_time
    }
    allGhostPost(
      limit: 3
      sort: { order: DESC, fields: [published_at] }
      filter: { slug: { ne: $slug }, primary_tag: { slug: { eq: $tag } } }
    ) {
      edges {
        node {
          id
          html
          title
          slug
          feature_image
          excerpt
          created_at(formatString: "DD MMMM YYYY")
          primary_author {
            name
            profile_image
          }
          primary_tag {
            name
            postCount
          }
          reading_time
        }
      }
    }
  }
`

class BlogPageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.blog = React.createRef()
    this.state = {
      routes: routes,
    }
  }

  componentDidMount() {
    document.querySelector(".navbar").classList.add("black")
    this.linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`
    this.twitterShareUrl = `https://twitter.com/share?url=${window.location.href}`
    this.facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
    this.url = process.env.BASE_URL
    this.blog.current.innerHTML = this.props.data.ghostPost.html
  }

  onSharePage(url) {
    const width = 650
    const height = 450
    window.open(
      url,
      "Share Contingent",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
        width +
        ",height=" +
        height +
        ",top=" +
        (window.screen.height / 2 - height / 2) +
        ",left=" +
        (window.screen.width / 2 - width / 2)
    )
  }

  render() {
    this.post = this.props.data.ghostPost
    this.related_posts = this.props.data.allGhostPost
    return (
      <LayoutDark>
        <Helmet>
          <base target="_blank"></base>
          <title>{this.post.title} - Contingent Blog</title>
          <meta name="title" content={this.post.title + "- Contingent Blog"} />
          <meta name="description" content={this.post.excerpt} />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/blog/${this.post.slug}`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/blog/${this.post.slug}`}
          />
          <meta
            property="og:title"
            content={this.post.title + "- Contingent Blog"}
          />
          <meta property="og:description" content={this.post.excerpt} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/blog/${this.post.slug}`}
          />
          <meta property="og:image" content={`${this.post.feature_image}`} />
        </Helmet>
        <div className={"section-container blog"}>
          <div className={"section-info basic"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column blog-page-container"}>
                  <div className={"blog-content-container"}>
                    <a
                      className={"page-tag"}
                      href={`/blog/tags/${this.post.primary_tag.slug}/`}
                    >
                      {this.post.primary_tag.name}
                    </a>
                    <h1 className={"title"}>{this.post.title}</h1>
                    <div className={"author-container"}>
                      <a
                        className={"author"}
                        href={`/blog/authors/${this.post.primary_author.slug}/`}
                      >
                        <div className={"group"}>
                          <img
                            className={"avatar"}
                            src={this.post.primary_author.profile_image}
                          />
                          <p className={"name"}>
                            {this.post.primary_author.name}
                          </p>
                        </div>
                      </a>
                      <div className={"read-time"}>
                        {this.post.reading_time} MIN READ
                      </div>
                    </div>
                    <div
                      className={"jumbotron"}
                      style={{
                        backgroundImage:
                          "url('" + this.post.feature_image + "')",
                      }}
                    />
                    <div className={"attribute"}>Image from Unsplash</div>
                    <article ref={this.blog}></article>
                    <div className={"socials"}>
                      <div
                        className={"icon"}
                        onClick={() => this.onSharePage(this.linkedinShareUrl)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.905"
                          height="13.905"
                          viewBox="0 0 13.905 13.905"
                        >
                          <path
                            d="M12.647,0H1.258A1.258,1.258,0,0,0,0,1.258v11.39a1.258,1.258,0,0,0,1.258,1.258h11.39a1.258,1.258,0,0,0,1.258-1.258V1.258A1.258,1.258,0,0,0,12.647,0ZM4.3,12.007a.366.366,0,0,1-.366.366H2.379a.366.366,0,0,1-.366-.366V5.475a.366.366,0,0,1,.366-.366H3.937a.366.366,0,0,1,.366.366ZM3.158,4.493a1.48,1.48,0,1,1,1.48-1.48A1.48,1.48,0,0,1,3.158,4.493Zm9.288,7.543a.337.337,0,0,1-.337.337H10.437a.337.337,0,0,1-.337-.337V8.972c0-.457.134-2-1.194-2-1.03,0-1.239,1.058-1.281,1.533v3.534a.337.337,0,0,1-.337.337H5.671a.337.337,0,0,1-.337-.337V5.445a.337.337,0,0,1,.337-.337H7.288a.337.337,0,0,1,.337.337v.57A2.3,2.3,0,0,1,9.784,5c2.677,0,2.662,2.5,2.662,3.876v3.161Z"
                            fill="#202630"
                            opacity="0.256"
                          />
                        </svg>
                      </div>
                      <div
                        className={"icon"}
                        onClick={() => this.onSharePage(this.twitterShareUrl)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.145"
                          height="11.766"
                          viewBox="0 0 14.145 11.766"
                        >
                          <path
                            d="M13.921,35.86a5.6,5.6,0,0,1-.975.329,3.041,3.041,0,0,0,.8-1.278h0a.159.159,0,0,0-.233-.186h0a5.628,5.628,0,0,1-1.491.616.38.38,0,0,1-.093.012.4.4,0,0,1-.261-.1,3.05,3.05,0,0,0-2.018-.762,3.26,3.26,0,0,0-.966.149,2.954,2.954,0,0,0-1.991,2.129,3.253,3.253,0,0,0-.072,1.123.109.109,0,0,1-.027.086.113.113,0,0,1-.084.038H6.5A8,8,0,0,1,1,35.079H1a.159.159,0,0,0-.261.02h0a3.053,3.053,0,0,0,.5,3.707,2.733,2.733,0,0,1-.7-.269h0a.159.159,0,0,0-.237.137h0a3.053,3.053,0,0,0,1.781,2.813H2.022a2.744,2.744,0,0,1-.514-.049h0a.159.159,0,0,0-.182.205h0a3.056,3.056,0,0,0,2.414,2.079,5.627,5.627,0,0,1-3.152.957H.236a.234.234,0,0,0-.228.176.242.242,0,0,0,.116.267,8.37,8.37,0,0,0,4.206,1.134,8.485,8.485,0,0,0,3.594-.762,7.937,7.937,0,0,0,2.63-1.971,8.64,8.64,0,0,0,1.61-2.7,8.52,8.52,0,0,0,.547-2.952v-.046a.519.519,0,0,1,.194-.4,6.028,6.028,0,0,0,1.213-1.331h0a.159.159,0,0,0-.2-.234Z"
                            transform="translate(0 -34.491)"
                            fill="#202630"
                            opacity="0.256"
                          />
                        </svg>
                      </div>
                      <div
                        className={"icon"}
                        onClick={() => this.onSharePage(this.facebookShareUrl)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.746"
                          height="16.746"
                          viewBox="0 0 16.746 16.746"
                        >
                          <path
                            d="M16.746,2.791A2.893,2.893,0,0,0,13.955,0H2.791A2.893,2.893,0,0,0,0,2.791V13.955a2.893,2.893,0,0,0,2.791,2.791H8.373V10.42H6.326V7.629H8.373V6.541a3.434,3.434,0,0,1,3.14-3.564h2.256V5.768H11.513c-.247,0-.535.3-.535.749V7.629h2.791V10.42H10.978v6.326h2.977a2.893,2.893,0,0,0,2.791-2.791Z"
                            fill="#192b4a"
                          />
                        </svg>{" "}
                      </div>
                    </div>
                    <div className={"subscription-box"}>
                      <div className={"title"}>Subscribe to our newsletter</div>
                      <p className={"description"}>
                        Get regular updates on how to achieve operational
                        resilience for your enterprise{" "}
                      </p>
                      <Newsletter mode={"light"}></Newsletter>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.related_posts.edges.length === 3
              ? `section-container blog`
              : `hidden`
          }
        >
          <div className={"section-info basic related-container"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column blog-page-container centered related"}>
                  <div className={"content"}>
                    <h1 className={"title"}>Related posts</h1>
                    <p className={"description"}></p>
                  </div>
                  <div className={"blog-list"}>
                    {this.related_posts.edges.map(({ node }) => (
                      <Link
                        className={"blog-card"}
                        key={node.id}
                        to={`/blog/${node.slug}`}
                      >
                        <div
                          className={"image"}
                          style={{
                            backgroundImage:
                              "url('" + node.feature_image + "')",
                          }}
                        />
                        <div className={"content"}>
                          <div className={"header"}>
                            <h6 className={"blog-tag"}>
                              {node.primary_tag.name}
                            </h6>
                          </div>
                          <h4 className={"title"}>{node.title}</h4>
                          <p className={"description"}>{node.excerpt}</p>
                          <div className={"card-footer"}>
                            <div className={"author"}>
                              <div className={"group"}>
                                <img
                                  className={"avatar"}
                                  src={node.primary_author.profile_image}
                                />
                                <p className={"name"}>
                                  {node.primary_author.name}
                                </p>
                              </div>
                              <p className={"read-time"}>
                                {node.reading_time} MIN READ
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}

export default BlogPageComponent
